window.onload = function () {
    const btn = document.querySelector('.button--sticky')
    const btnPump = document.querySelector('.billboard__buttons--pump')

    if (btn && btnPump) {
        const changePosition = () => {
            if (btnPump.getBoundingClientRect().top <= btn.getBoundingClientRect().top) {
                btn.style.opacity = `0`
                btnPump.style.opacity = `1`
            } else {
                btn.style.opacity = `1`
                btnPump.style.opacity = `0`
            }
        }

        window.addEventListener('scroll', changePosition)
        window.addEventListener('resize', changePosition)


        changePosition()
    }

}