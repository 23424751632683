import _ from 'lodash'
import './modules/fullscreen-mobile'
import './modules/utm'
import './modules/popup'
import './modules/projects'
import './modules/form'
import './modules/sticky-btn'
import { initMetrics } from './modules/metrics'

initMetrics()

