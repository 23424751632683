const body = document.querySelector('body')
const popupsProjects = document.querySelectorAll('.popup-projects')

const linkPopupProjects = body.querySelectorAll('[data-projects]')

const isEscapeClosePopupProjects = (event) => {
  if (event.key == 'Escape') {
    popupsProjects.forEach((popup) => {
      popup.classList.remove('popup-projects-open')
    })
    body.classList.remove('no-scroll')
    document.removeEventListener('keydown', isEscapeClosePopupProjects)
  }
}

popupsProjects.forEach((popup) => {
  let buttonCloseProjects = popup.querySelector('.popup-projects__close-button')
  let buttonClickForm = popup.querySelector('.popup-projects__button')
  buttonCloseProjects.addEventListener('click', () => {
    body.classList.remove('no-scroll')
    popup.classList.remove('popup-projects-open')
    document.removeEventListener('keydown', isEscapeClosePopupProjects)
  })

  buttonClickForm.addEventListener('click', () => {
    body.classList.remove('no-scroll')
    popup.classList.remove('popup-projects-open')
    document.removeEventListener('keydown', isEscapeClosePopupProjects)
  })
  
  popup.addEventListener('click', (event) => {
    const target = event.target.closest('.popup-projects__content')
    if (!target) {
      popup.classList.remove('popup-projects-open')
      body.classList.remove('no-scroll')
      document.removeEventListener('keydown', isEscapeClosePopupProjects)
    }
  })
})

linkPopupProjects.forEach((link) => {
  link.addEventListener('click', (event) => {
    event.preventDefault()
    const popup = body.querySelector(`${link.dataset.projects}`)
    popup.classList.add('popup-projects-open')
    body.classList.add('no-scroll')
    document.addEventListener('keydown', isEscapeClosePopupProjects)
  })
})
