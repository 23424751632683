import IMask from 'imask';

const body = document.querySelector('body')
const form = document.querySelector('.form')
const popupsForm = document.querySelectorAll('.popup-form')

const linkPopupForm = body.querySelectorAll('[data-form]')
const phoneInput = document.querySelector('.form__input--phone')
const linkInput = document.querySelector('.form__input--link')
const scoreInput = document.querySelector('.form__input--score')
const submitButton = document.querySelector('.form__button')
const check1 = document.querySelector('#check')
const check2 = document.querySelector('#check2')

const handleCheckboxesInput = () => {
  submitButton.disabled = !(check1.checked && check2.checked)
}

check1.addEventListener('input', handleCheckboxesInput)
check2.addEventListener('input', handleCheckboxesInput)

const isEscapeClosePopupForm = (event) => {
  if (event.key == 'Escape') {
    popupsForm.forEach((popup) => {
      popup.classList.remove('popup-form-open')
      popup.classList.remove('popup-form--success')
    })
    body.classList.remove('no-scroll')
    document.removeEventListener('keydown', isEscapeClosePopupForm)
  }
}

popupsForm.forEach((popup) => {
  let buttonCloseForm = popup.querySelector('.popup-form__close-button')
  buttonCloseForm.addEventListener('click', () => {
    body.classList.remove('no-scroll')
    popup.classList.remove('popup-form-open')
    popup.classList.remove('popup-form--success')
    document.removeEventListener('keydown', isEscapeClosePopupForm)
  })
  popup.addEventListener('click', (event) => {
    const target = event.target.closest('.popup-form__content')
    if (!target) {
      popup.classList.remove('popup-form-open')
      body.classList.remove('no-scroll')
      popup.classList.remove('popup-form--success')
      document.removeEventListener('keydown', isEscapeClosePopupForm)
    }
  })
})

linkPopupForm.forEach((link) => {
  link.addEventListener('click', (event) => {
    event.preventDefault()
    const popup = body.querySelector(`${link.dataset.form}`)
    popup.classList.add('popup-form-open')
    body.classList.add('no-scroll')
    handleCheckboxesInput()
    document.addEventListener('keydown', isEscapeClosePopupForm)
  })
})



const fetchData = async (data) => {
  submitButton.disabled = true
  try {
    await fetch('https://tachka.fun/dashboard/api/register', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })

    popupsForm.forEach((popup) => {
      popup.classList.add('popup-form--success');
    })
    form.reset()
    submitButton.disabled = false
  } catch (err) {
    alert('Ошибка отправки формы')
    submitButton.disabled = false
    console.error(err)
  }
  submitButton.disabled = false
}

IMask(
    phoneInput,
    {
      mask: '+{7} (000) 000-00-00'
    }
)

IMask(
    scoreInput,
    {
      mask: '00000000'
    }
)

phoneInput.addEventListener('input', () => {
  phoneInput.setCustomValidity('')
})

linkInput.addEventListener('input', () => {
  linkInput.setCustomValidity('')
})

const isValidUrl = (url = '') => {
  try {
    return Boolean(new URL(url));
  }
  catch(e){
    return false;
  }
}


form.addEventListener('submit', (evt) => {
  evt.preventDefault()
  const formData = new FormData(evt.currentTarget);
  const data = {}
  for (const [key, value] of formData) {
    data[key] = value
  }

  if (phoneInput.value.length < 18) {
    phoneInput.setCustomValidity('Некорректный номер телефона')
    phoneInput.reportValidity();
    return
  }

  if (!isValidUrl(data.link)) {
    linkInput.setCustomValidity('Введите ссылку формата http://pari.ru/photo')
    linkInput.reportValidity();
    return
  }

  fetchData({
    "full_name": data.name,
    "account_number": data.score,
    "phone_number": data.phone,
    "description": data.description,
    "photo_url": data.link
  })
})
